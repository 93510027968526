import React from 'react';
import { Component } from "react";
import { Route, Routes } from "react-router";
import HomePage from "./pages/home/HomePage";
import AdminTeam from "./pages/AdminTeam";
import VipList from "./pages/VipList";
import NotFound from "./pages/NotFound";
import ForumRedirect from "./pages/ForumRedirect";
import AboutServer from "./pages/AboutServer";
import Pravidla from './pages/rules/Pravidla';
import GDPRAudit from "./pages/rules/GDPRAudit";
import ObchodniPodminky from "./pages/rules/ObchodniPodminky";
import AllowedMods from "./pages/rules/AllowedMods";
import ContactPage from "./pages/ContactPage";
import TenYearsAniversary from './pages/10-years';
import { withRouter } from './utils/withRouter';
import TheEnd from './pages/the-end';

class MainRouter extends Component<any, any> {

	public render() {
		return(
			<Routes>

				{/* PAGE ROUTES */}
				<Route path="/" element={<TheEnd/>}/>
				<Route path="/admin-team" element={<AdminTeam/>}/>
				<Route path="/vip" element={<VipList/>}/>
				<Route path="/o-serveru" element={<AboutServer/>}/>
				<Route path="/pravidla/zasady-zpracovani-osobnich-udaju" element={<GDPRAudit/>}/>
				<Route path="/pravidla/obchodni-podminky/" element={<ObchodniPodminky/>}/>
				<Route path="/pravidla/povolene-mody/" element={<AllowedMods/>}/>
				<Route path="/pravidla/" element={<Pravidla/>}/>
				<Route path="/kontakt" element={<ContactPage/>}/>
				<Route path="/10-anniversary" element={<TenYearsAniversary/>}/>

				{/* FORUM FIX ROUTES */}
				<Route path="/forum" element={<ForumRedirect/>}/>
				<Route path="/tema" element={<ForumRedirect/>}/>
				<Route path="/uzivatel" element={<ForumRedirect/>}/>
				<Route path="/zpravy" element={<ForumRedirect/>}/>
				<Route path="/ucet" element={<ForumRedirect/>}/>

				<Route path="*" element={<NotFound/>}/>
			</Routes>
		);
	}

}

export default withRouter(MainRouter);
