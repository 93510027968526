import React from "react";
import { Component } from "react";
import PageContainer from "../components/PageContainer";
// @ts-ignore
//import { SRLWrapper } from "simple-react-lightbox";

class TheEnd extends Component<any, any> {
    public render() {
        return (
            <PageContainer>
                <section className="bg-half d-table w-100" style={{backgroundImage: `url(https://static.craftmania.cz/legacy-screens/lobby-2019-3.png)`}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> CraftMania: The End </h4>
									
								</div>
							</div>
						</div>
					</div>
				</section>

                <section className="section bg-light">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="section-title mb-4 pb-5">
                                    <h4 className="title mb-4">Vážení hráči / Vážené hráčky,</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        před více jak deseti lety, vznikl malý server CraftMania, který se postupně rozrostl do ohromných rozměrů, který dokázal konkurovat i pár serverům v zahraničí. Napříč deseti lety, CraftMania poskytovala hráčům herní servery, minihry ale i módované servery.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-primary font-weight-bold">Před více než deseti lety</span>, jsem byl malý kiddo, co se zajímal o Minecraft, založil tento server a postupně se z něj stala jedna velká životní cesta. Cesta která mě následovala každý den, skoro polovinu mého života. Psát tento text a ukončit něco takového, je pro mě nadmíru obtížné. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Společně jsme dokázali vytvořit neskutečně moc vzpomínek, zážitků, přátelství a možná i vztahů. Kdo by kdy řekl, že něco takového dokáže „Minecraft server“. Za deset let působilo v Admin Teamu desítky, až-li by se dalo říct i stovky členů AT, kteří tvořili základ serveru a tímto jim patří <span className="text-primary font-weight-bold">moje poděkování</span>. Bez nich by nešlo tvořit tyto vzpomínky.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Ačkoliv každý může mít na server názor jakýkoliv, že již posledních pár let nebyl to co býval, že za to mohu já. Ve své podstatě se nikdy nic nezměnilo, můj přístup k novinkám, Minecraftu a komunitě zůstal stejný a navždy zůstane i po konci. CraftMania vždy byla postavená na tom, držet poslední trendy a novinky poskytovat hráčům ve svém podání. Vzniklo zde tedy <span className="text-primary font-weight-bold">přes 36 „survival“ herních modů</span> zahrnující Survival, Skyblock, OneBlock, Frakce, Vanilla a nebo Vanilla Skyblock. Server vznikl na verzi <span className="text-warning font-weight-bold">1.7.2</span> a dnes zde máme verzi <span className="text-success font-weight-bold">1.21.1</span>. Všechny verze mezi tvořily hlavní pilíře těchto herních módů, které jsme poskytovali, tak jak vznikly ve svém základu v roce 2013. Příkladem může být do dnes stále objevované „fly zdarma“, nebo WorldEdit na Creativu a jiné.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Nebyly to ale jenom herní módy, ale i minihry. Do dnes mnoha hráči vzpomínané <span className="text-primary font-weight-bold">BedWars, SkyWars, BuildBattle nebo KitPvP</span> jsou jedny z nezapomenutelných součástí historie serveru. Celkově za deset let zde bylo přes 60 různých miniher, a to je opravdu hodně! Vyjímkou nebyly ani módované servery, pár jich zde bylo, ale nikdy jsme se nepouštěli do větších módpacků, avšak vzpomínky na Teggit jsou už legendární.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Bylo toho zkrátka hodně, a hodně bylo i hráčů skoro <span className="text-primary font-weight-bold">jeden milion a čtvrt registrovaných hráčů</span>, jenom dokazuje, že CraftMania nebyl žádný malý server. Těmto milion hráčům také patří velký dík, jelikož bez nich by server nemohl fungovat a udržet se deset let. Přece jenom, komunita serveru je to, co tvoří server. Dokázali jsme tak <span className="text-primary font-weight-bold">v lednu 2016 udělat rekord 1514 hráčů</span>, kteří tehdy hráli v jednu chvíli na serveru, což bylo o 300 více než na konkurenčním největším serveru v CZ/SK.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Dnes jsme již ale někde jinde, většina z nás původních již Minecraft nehraje, většina má už svoje rodiny a jiné problémy, většina už nemá čas ani hrát hry. Server přežil jednu generaci hráčů, a i já mám jiné plány a zájmy, než se starat o Minecraft server. Tak po dlouhém, ale opravdu dlouhém zvažování a osobním vyrovnáváním se s skutečností, že je již opravdu čas jít vpřed, a posunout se v životě dál.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-danger font-weight-bold">Jsem se rozhodl, že server CraftMania.cz bude dne 24.11.2024 ve 21:00 vypnut, permanentně.</span>
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Hráči, kteří aktuálně hrají na serveru nemusí zoufat, že bych jim server vypnul. Aktuální server se přesune pod tajnou IP adresu v den vypnutí veřejné IP adresy, a budou tak moct hrát na současné verzi dokud budou chtít. Veškeré informace budou poskytnuty na našem Discordu ve speciálním kanálu <code>#konec-serveru-info</code>. Avšak <span className="text-primary font-weight-bold">server již nikdy nebude updatován na novou verzi</span>, včetně oprav bugů a podpory ze strany AT.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Každý příběh jako tento má i svůj epilog, a tím je připravovaná kniha <span className="text-primary font-weight-bold">CraftMania: Legacy</span>. Tento text je tedy i součástí připravované knihy, na kterou se můžete těšit příští rok. Jak jsem několikrát již teasoval, budou zde veškeré nikdy nezveřejněné informace o serveru, mnoho příběhů hráčů a členů AT, a hlavně také příběh CraftManie napříč deseti lety pospolu se mnou, taková menší biografie. Více informací v kanále <code>#kniha_info</code> na našem Discordu.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Avšak pokud neplánuješ číst připravovanou knihu, tímto zde <span className="text-primary font-weight-bold">náš společný příběh končí</span>. A já ti chci poděkovat, bez každého jednotlivého hráče, by toto nešlo napsat, a za to ti patří velký dík. A doufám, že si někdy na CraftManii vzpomeneš, třeba za dalších deset let.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-primary font-weight-bold">Děkuji</span>, já nikdy nezapomenu!<br/>
                                        Majitel serveru - Wake
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <i>Pokračování v knize...</i>
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Souhrn 10 let serveru ve speciálním tématu <a href="https://craftmania.cz/10-anniversary" target="_blank" rel="noopener noreferrer">zde</a>.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PageContainer>
        );
    }
}

export default TheEnd;